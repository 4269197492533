import React from "react";
import { motion, AnimatePresence } from "framer-motion";

import NameForm from "./form";

import { ASSET_BASE_URL } from "../constants";

const AddBox = ({ isVisible, onClickHandler }) => {
  return (
    <div className='addButton'>
      <AnimatePresence>
        {isVisible && (
          <motion.div
            className='add-box'
            initial={{ opacity: 0, scale: 0.75 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0 }}
          >
            <div className='addMore'>
            <h5>Submit more characters</h5>
            <NameForm /></div>
          </motion.div>
        )}
      </AnimatePresence>
      <img
        onClick={onClickHandler}
        src={`${ASSET_BASE_URL}/plus-circle.svg`}
        alt='add'
      ></img>
    </div>
  );
};

export default AddBox;
