import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { ASSET_BASE_URL } from "../constants";

const InfoBox = ({ isVisible, onClickHandler }) => {
  return (
    <div className='infoButton'>
      <AnimatePresence>
        {isVisible && (
          <motion.div
            className='info-box'
            initial={{ opacity: 0, scale: 0.75 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0 }}
          >
            <b>How to use?</b>
            <br></br>Insert your character or symbol in the 'Search' field
            below. <br></br> <i>e.g try to put '?'</i>
          </motion.div>
        )}
      </AnimatePresence>
      <img
        onClick={onClickHandler}
        src={`${ASSET_BASE_URL}/lightbulb.svg`}
        alt='info'
      ></img>
    </div>
  );
};

export default InfoBox;
