import React, { useState, useEffect } from "react"
import axios from "axios"
import Helmet from "react-helmet"

import "../styles/styles.css"

import items from "../helpers/items"

import InfoBox from "../components/InfoBox"
import AddBox from "../components/AddBox"
import { API_BASE_URL } from "../constants"

const INFO_BOX_INDEX = 0
const ADD_BOX_INDEX = 1

export default function App() {
  const [searchValue, setSearchValue] = useState("")
  const [isInitial, setIsInitial] = useState(true)
  const [showBox, setShowBox] = useState(false)
  const [showBoxIndex, setShowBoxIndex] = useState(INFO_BOX_INDEX)

  const onChangeInputHandler = e => {
    const value = e.target.value
    if (value === "") {
      setIsInitial(true)
    } else {
      setIsInitial(false)
    }
    setSearchValue(value)
  }

  const renderSymbols = () => {
    return (
      <div
        className="table"
        id="main"
        role="tabpanel"
        aria-labelledby="main-link"
        tabindex="0"
      >
        <section id="sec-main">
          <table className="table">
            <thead>
              {!isInitial ? (
                <tr>
                  <th style={{ textAlign: "center" }}>Character</th>
                  <th style={{ textAlign: "center" }}>Name</th>
                  <th style={{ textAlign: "center" }}>Unicode</th>
                </tr>
              ) : (
                ""
              )}
            </thead>
            <tbody id="search-values">
              {items.map(item => {
                const isVisible = item.character.includes(searchValue)
                return isVisible && !isInitial ? (
                  <tr>
                    <td style={{ textAlign: "center" }}>{item.character}</td>
                    <td style={{ textAlign: "center" }}>{item.name}</td>
                    <td style={{ textAlign: "center" }}>{item.unicode}</td>
                  </tr>
                ) : null
              })}
            </tbody>
          </table>
        </section>
      </div>
    )
  }

  const infoBoxHandler = () => {
    if (showBoxIndex === INFO_BOX_INDEX) {
      setShowBox(!showBox)
    } else {
      setShowBoxIndex(INFO_BOX_INDEX)
      setShowBox(true)
    }
  }

  const addBoxHandler = () => {
    if (showBoxIndex === ADD_BOX_INDEX) {
      setShowBox(!showBox)
    } else {
      setShowBoxIndex(ADD_BOX_INDEX)
      setShowBox(true)
    }
  }

  useEffect(() => {
    axios.get(`${API_BASE_URL}/symbols`).then(res => {
      console.log(res)
    })
  }, [])

  const packageName = "Whats the Symbol?"
  const excerpt =
    "This website display all characters and symbols; What is the symbol called; What is this symbol called; Whats the symbol; Whats this symbol; What the symbol; What this symbol; what"

  return (
    <div className="App">
      <Helmet>
        <title>{packageName}</title>
        <meta name="description" content={excerpt} />
        <meta property="og:description" content={excerpt} />
        <meta name="twitter:description" content={excerpt} />
        <meta property="og:title" content={packageName} />
        <meta property="og:type" content="article" />
      </Helmet>
      <div className="button">
        <InfoBox
          isVisible={showBox && showBoxIndex === INFO_BOX_INDEX}
          onClickHandler={infoBoxHandler}
        />
        <AddBox
          isVisible={showBox && showBoxIndex === ADD_BOX_INDEX}
          onClickHandler={addBoxHandler}
        />
      </div>
      <div>
        <h1 className="titleFront">What's The Symbol?</h1>
        <input
          value={searchValue}
          className="search-box"
          placeholder="Search"
          onChange={onChangeInputHandler}
        />
      </div>
      <div className="App__bottom">{renderSymbols()}</div>
    </div>
  )
}
