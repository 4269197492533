import React from "react"
import axios from "axios"
import { API_BASE_URL } from "../constants"

class NameForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = { value: "" }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange(event) {
    this.setState({ value: event.target.value })
  }

  handleSubmit(event) {
    axios
      .post(`${API_BASE_URL}/symbol`, {
        name: this.state.value,
      })
      .then(res => {
        console.log("res", res)
      })
    event.preventDefault()
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit} className="addMore_box">
        <label className="addMore_label">
          Name/Symbol:
          <input
            className="addMore_input"
            type="text"
            value={this.state.value}
            onChange={this.handleChange}
          />
        </label>
        <input type="submit" value="Submit" class="addMore_submit" />
      </form>
    )
  }
}

export default NameForm
