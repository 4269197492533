export default [
  {
    character: "@",
    name: "At",
    unicode: "U+0040"
  },
  {
    character: "*",
    name: "Asterisk",
    unicode: "U+002A"
  },
  {
    character: ")",
    name: "Right parenthesis",
    unicode: "U+0029"
  },
  {
    character: "+",
    name: "Plus",
    unicode: "U+002A"
  },
  {
    character: ",",
    name: "Comma",
    unicode: "U+002C"
  },
  {
    character: "-",
    name: "Minus (Hypen)",
    unicode: "U+002D"
  },
  {
    character: ".",
    name: "Full stop",
    unicode: "U+002E"
  },
  {
    character: ";",
    name: "Semicolon",
    unicode: "U+003B"
  },
  {
    character: ":",
    name: "Colon",
    unicode: "U+003A"
  },
  {
    character: "/",
    name: "Slash",
    unicode: "U+002F"
  },
  {
    character: "=",
    name: "Equal sign",
    unicode: "U+003D"
  },
  {
    character: "<",
    name: "Less than",
    unicode: "U+003C"
  },
  {
    character: ">",
    name: "Greater than",
    unicode: "U+003E"
  },
  {
    character: "?",
    name: "Question bracket",
    unicode: "U+003F"
  },
  {
    character: "[",
    name: "Left bracket",
    unicode: "U+005B"
  },
  {
    character: "\\",
    name: "Backslash",
    unicode: "U+005C"
  },
  {
    character: "]",
    name: "Right bracket",
    unicode: "U+005D"
  },
  {
    character: "^",
    name: "Caret",
    unicode: "U+005E"
  },
  {
    character: "|",
    name: "Vertical bar",
    unicode: "U+007C"
  },
  {
    character: "{",
    name: "Curly left bracket",
    unicode: "U+007B"
  },
  {
    character: "`",
    name: "Grave accent (backtick)",
    unicode: "U+0060"
  },
  {
    character: "_",
    name: "Underscore",
    unicode: "U+005F"
  },
  {
    character: "~",
    name: "Tilde",
    unicode: "U+007E"
  },
  {
    character: "}",
    name: "Curly right bracket",
    unicode: "U+007D"
  },
  {
    character: "!",
    name: "Exclamation",
    unicode: "U+0021"
  },
  {
    character: '"',
    name: "Double quote",
    unicode: "U+0022"
  },
  {
    character: "#",
    name: "Number sign (hash)",
    unicode: "U+0023"
  },
  {
    character: "$",
    name: "Dollar sign",
    unicode: "U+0024"
  },
  {
    character: "%",
    name: "Percent",
    unicode: "U+0025"
  },
  {
    character: "&",
    name: "Ampersand",
    unicode: "U+0026"
  },
  {
    character: "'",
    name: "Single quote",
    unicode: "U+0027"
  },
  {
    character: "(",
    name: "Left parenthesis",
    unicode: "U+0028"
  }
];
